<template>
  <div class="pa-0 invite fill-height" fluid>
    <v-card class="primary pa-7 fill-width text-center" flat tile>
      <router-link to="/">
        <img
          alt="TestApp.io"
          class="max-h-50px"
          src="/media/logos/logo_light_155x30.png"
        />
      </router-link>
    </v-card>
    <div
      v-if="!verifyingLink"
      class="invite-content flex-row-fluid d-flex flex-column align-center flex-md-center position-relative pt-7 px-7 pb-4 mx-auto font-size-h5"
    >
      <h3 class="bold-text font-size-h2 text-center">
        You’ve been invited to join
      </h3>
      <h3 class="bold-text font-size-h2 text-center">
        <span class="text-primary">{{ invite.team.name }}</span> team!
      </h3>
      <v-divider />
      <div class="d-flex flex-column align-center pr-7 pl-7">
        <div class="invite-date text-muted font-size-h6">
          {{ getTimelineTime(invite.created_at) }}
        </div>

        <div v-if="invite.message">
          <div class="font-size-h3 pa-4">"{{ invite.message }}"</div>
        </div>
        <div v-if="invite.message" class="px-4">
          - {{ invite.from_user.name }}
        </div>
        <div
          class="font-size-h4 pa-4 border-bottom text-muted"
          style="width: 100%"
        >
          Invited by:
          <span v-if="!invite.message">{{ invite.from_user.name }} -</span>
          {{ invite && invite.from_user ? invite.from_user.email : "" }}
        </div>
      </div>
      <vue-inline-alert v-if="error" :message="error" message-type="error" />
      <vue-inline-alert
        v-if="success"
        message="Successfully signed up!"
        message-type="success"
        secondaryMessage="Please check your email to activate your account and join the team"
      />
    </div>
    <div v-if="!verifyingLink" class="justify-center">
      <v-row
        align="center"
        align-content="center"
        class="px-0"
        justify="center"
      >
        <v-col class="text-center px-0" cols="12" lg="4" md="5" sm="10">
          <auth-check
            :invite-email="invite.email"
            class="mx-auto"
            from-invite
          ></auth-check>
        </v-col>
      </v-row>
    </div>
    <v-container
      v-else
      class="bold-text d-flex justify-center align-center py-15 my-15"
    >
      <div class="text-center">
        <content-loader />
        <h3
          :class="{ 'font-size-h1': $vuetify.breakpoint.lgAndUp }"
          class="font-size-h4 mt-3"
        >
          Please wait verifying link ...
        </h3>
      </div>
    </v-container>
    <vue-snack-bar></vue-snack-bar>
  </div>
</template>

<style lang="scss">
// @import "@/assets/sass/custom.scss";
// @import "@/assets/sass/pages/invite/invite.scss";
</style>

<script>
import { mapState } from "vuex";
import { GET_INVITE } from "@/store/team/invite.module";
import { get, getTimelineTime } from "@/core/services/helper.service";
import AuthCheck from "@/view/components/Common/AuthCheck";

export default {
  components: { AuthCheck },
  name: "invite",
  metaInfo: {
    title: "Invite | TestApp.io - Portal Sign In",
    meta: [
      {
        name: "robots",
        content: "noindex,nofollow",
      },
    ],
  },
  data() {
    return {
      get,
      message: "",
      email: "",
      busy: false,
      verifyingLink: true,
      invite: {},
      form: {},
      getTimelineTime,
      error: "",
      success: "",
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
  watch: {
    invite: function (value) {
      if (value && value.email) {
        this.form.email = value.email;
      }
    },
  },
  async created() {
    // const os = this.getMobileOperatingSystem();
    // if (os == "android" || os == "ios") {
    if (this.$route.params.invite_id) {
      const invite_id = this.$route.params.invite_id;
      await this.$store
        .dispatch(GET_INVITE, invite_id)
        .then((response) => {
          this.invite = response.data;
          this.verifyingLink = false;
        })
        .catch((err) => {
          this.$router.push({
            name: "error",
            params: {
              message: err.message,
            },
          });
        });
    } else {
      this.$router.push({
        name: "error",
        params: {
          message: "Invalid request",
        },
      });
    }
  },
  methods: {},
};
</script>
