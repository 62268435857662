var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-0 invite fill-height",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', {
    staticClass: "primary pa-7 fill-width text-center",
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticClass: "max-h-50px",
    attrs: {
      "alt": "TestApp.io",
      "src": "/media/logos/logo_light_155x30.png"
    }
  })])], 1), !_vm.verifyingLink ? _c('div', {
    staticClass: "invite-content flex-row-fluid d-flex flex-column align-center flex-md-center position-relative pt-7 px-7 pb-4 mx-auto font-size-h5"
  }, [_c('h3', {
    staticClass: "bold-text font-size-h2 text-center"
  }, [_vm._v(" You’ve been invited to join ")]), _c('h3', {
    staticClass: "bold-text font-size-h2 text-center"
  }, [_c('span', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s(_vm.invite.team.name))]), _vm._v(" team! ")]), _c('v-divider'), _c('div', {
    staticClass: "d-flex flex-column align-center pr-7 pl-7"
  }, [_c('div', {
    staticClass: "invite-date text-muted font-size-h6"
  }, [_vm._v(" " + _vm._s(_vm.getTimelineTime(_vm.invite.created_at)) + " ")]), _vm.invite.message ? _c('div', [_c('div', {
    staticClass: "font-size-h3 pa-4"
  }, [_vm._v("\"" + _vm._s(_vm.invite.message) + "\"")])]) : _vm._e(), _vm.invite.message ? _c('div', {
    staticClass: "px-4"
  }, [_vm._v(" - " + _vm._s(_vm.invite.from_user.name) + " ")]) : _vm._e(), _c('div', {
    staticClass: "font-size-h4 pa-4 border-bottom text-muted",
    staticStyle: {
      "width": "100%"
    }
  }, [_vm._v(" Invited by: "), !_vm.invite.message ? _c('span', [_vm._v(_vm._s(_vm.invite.from_user.name) + " -")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.invite && _vm.invite.from_user ? _vm.invite.from_user.email : "") + " ")])]), _vm.error ? _c('vue-inline-alert', {
    attrs: {
      "message": _vm.error,
      "message-type": "error"
    }
  }) : _vm._e(), _vm.success ? _c('vue-inline-alert', {
    attrs: {
      "message": "Successfully signed up!",
      "message-type": "success",
      "secondaryMessage": "Please check your email to activate your account and join the team"
    }
  }) : _vm._e()], 1) : _vm._e(), !_vm.verifyingLink ? _c('div', {
    staticClass: "justify-center"
  }, [_c('v-row', {
    staticClass: "px-0",
    attrs: {
      "align": "center",
      "align-content": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center px-0",
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "5",
      "sm": "10"
    }
  }, [_c('auth-check', {
    staticClass: "mx-auto",
    attrs: {
      "invite-email": _vm.invite.email,
      "from-invite": ""
    }
  })], 1)], 1)], 1) : _c('v-container', {
    staticClass: "bold-text d-flex justify-center align-center py-15 my-15"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('content-loader'), _c('h3', {
    staticClass: "font-size-h4 mt-3",
    class: {
      'font-size-h1': _vm.$vuetify.breakpoint.lgAndUp
    }
  }, [_vm._v(" Please wait verifying link ... ")])], 1)]), _c('vue-snack-bar')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }